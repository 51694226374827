<template>
  <div>
          <div class=" mt-4">
            <div class="step_sub_title">
              Please upload your ID Vertification 请上传您的ID认证
            </div>
            <ul class="step_ul_style">
              <li>
                Face-to-face channel: with Skyline staff assistance to verify
                your documents.
              </li>
              <li>
                Non face-to-channel: without Skyline staff assistance to verify
                your documents.
              </li>
            </ul>
          </div>
          <div>
          <a-form-model-item
            :style="GetScreenStyle.width100percent"
            label="Choose the channel before uploading your proof of identification:"
            :prop="proveChannel_str"
          >
            <a-select
              :disabled='disabled'
              @change="(val) => handleChange(proveChannel_str, val)"
              :value="proveChannel"
            >
              <a-select-option value="face_to_face"
                >Face-to-face Channel</a-select-option
              >
              <a-select-option value="none_face_to_face"
                >None Face-to-face Channel
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- face_to_face 上传 -->
          <a-form-model-item
            v-if="proveChannel == 'face_to_face'"
            :style="GetScreenStyle.width100percent"
            :prop="proveMethod_str"
            label="Please choose ONE of the following:"
          >
            <a-radio-group
              class="modify_antd_radio"
              :disabled='disabled'
              name="face_to_face_type"
              :value="proveMethod"
              @change="({target:{value}}) => handleChange(proveMethod_str, value)"
            >
              <a-radio :style="radioStyle" value="nzauPassport">
                <ul class="radio_step_ul_style">
                  <li class="li_require">
                    New Zealand/Australia Passport 新西兰/澳大利亚护照 (the copy
                    of passport photo and signature page 护照信息页和签字页)
                  </li>
                </ul>
              </a-radio>
              <a-radio :style="radioStyle" value="cnPassport_cnIdentify">
                <ul class="radio_step_ul_style">
                  <li class="li_require">
                    Chinese passport 中国护照 (the copy of passport photo,
                    signature page and visa page 护照信息页，签字页和签证页)
                  </li>
                  <li class="li_require">
                    Chinese National ID (the copy of front and back side
                    身份证正反面)
                  </li>
                </ul>
              </a-radio>
              <a-radio :style="radioStyle" value="cnPassport_nzauDriver">
                <ul class="radio_step_ul_style">
                  <li class="li_require">
                    Chinese passport 中国护照 (the copy of passport photo, signature page and visa page 护照信息页，签字页和签证页);
                  </li>
                  <li class="li_require">
                    New Zealand/Australia driver licence (the copy of front and back side 新西兰/澳大利亚驾照正反面)
                  </li>
                </ul>
              </a-radio>
              <a-radio :style="radioStyle" value="otherPassport_nzauDriver">
                <ul class="radio_step_ul_style">
                  <li class="li_require">
                    Other passport 其他护照 (the copy of passport photo,
                    signature page and visa page 护照信息页，签字页和签证页)
                  </li>
                  <li class="li_require">
                    New Zealand/Australia driver licence (the copy of front and
                    back side 新西兰/澳大利亚驾照正反面)
                  </li>
                </ul>
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <!-- none-face-to-face 证件上传 -->
          <a-form-model-item
            v-if="proveChannel == 'none_face_to_face'"
            :style="GetScreenStyle.width100percent"
            :prop="proveMethod_str"
            label="Please choose ONE of the following:"
          >
            <a-radio-group
              class="modify_antd_radio"
              :disabled='disabled'
              name="none_face_to_face_type"
              :value="proveMethod"
              @change="({target:{value}}) => handleChange(proveMethod_str, value)"
            >
              <a-radio :style="radioStyle" value="nzauPassport_nzauDriver">
                <ul class="radio_step_ul_style">
                  <li class="li_require">
                    New Zealand/Australia Passport 新西兰/澳大利亚护照 (the copy
                    of passport photo and signature page 护照信息页和签字页)
                  </li>
                  <li class="li_require">
                    New Zealand/Australia driver licence (the copy of front and
                    back side 新西兰/澳大利亚驾照正反面)
                  </li>
                </ul>
              </a-radio>
              <a-radio :style="radioStyle" value="nzauDriver_cnIdentify">
                <ul class="radio_step_ul_style">
                  <li class="li_require">
                    New Zealand/Australia driver licence (the copy of front and
                    back side 驾照正反面)
                  </li>
                  <li class="li_require">
                    Chinese National ID (the copy of front and back side
                    身份证正反面)
                  </li>
                  <li>
                    Chinese Passport (the copy of passport photo, signature page
                    and visa page 护照信息页，签字页和签证页《如有》)
                  </li>
                </ul>
              </a-radio>
              <a-radio :style="radioStyle" value="cnIdentify_cnBank_cnPassport">
                <ul class="radio_step_ul_style">
                  <li class="li_require">
                    Chinese National ID (the copy of front and back side
                    身份证正反面)
                  </li>
                  <li class="li_require">
                    Chinese bank card (the copy of front and back side
                    银行卡正面)
                  </li>
                  <li>
                    Chinese Passport (the copy of passport photo, signature page
                    and visa page 护照信息页，签字页和签证页《如有》)
                  </li>
                </ul>
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <div v-if="proveMethod">
             <AFileMessage
              v-for="item in idStructure[proveChannel][proveMethod]"
              :key="item.id"
              :disabled='disabled'
              :obj='item'
              @handleFileChange="handleFileList"
              @handleInput='handleInput'
              @FileValidate='FileValidate'
              @handleSelect='handleSelect'
            />
          </div>
          </div>
        </div>
</template>

<script>
import AFileMessage from '@/components/AFileMessage.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  created () {
  },
  components: {
    AFileMessage
  },
  data () {
    return {
      radioStyle: {
        lineHeight: '30px',
        width: '100%',
        marginBottom: '10px'
      }
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    proveChannel: {
      type: String
    },
    proveChannel_str: {
      type: String
    },
    proveMethod: {
      type: String
    },
    proveMethod_str: {
      type: String
    },
    nz_au_passport_num: {
      type: String
    },
    nz_au_passport_num_str: {
      type: String
    },
    nz_au_passport_country: {
      type: String
    },
    nz_au_passport_country_str: {
      type: String
    },
    nz_au_passport_file: {
      tpye: Array
    },
    nz_au_passport_file_str: {
      type: String
    },
    nz_au_driver_num: {
      type: String
    },
    nz_au_driver_num_str: {
      type: String
    },
    nz_au_driver_country: {
      type: String
    },
    nz_au_driver_country_str: {
      type: String
    },
    nz_au_driver_front_file: {
      tpye: Array
    },
    nz_au_driver_front_file_str: {
      type: String
    },
    nz_au_driver_back_file: {
      tpye: Array
    },
    nz_au_driver_back_file_str: {
      type: String
    },
    cn_passport_num: {
      type: String
    },
    cn_passport_num_str: {
      type: String
    },
    cn_passport_country: {
      type: String
    },
    cn_passport_country_str: {
      type: String
    },
    cn_passport_file: {
      tpye: Array
    },
    cn_passport_file_str: {
      type: String
    },
    cn_passport_visa_file: {
      tpye: Array
    },
    cn_passport_visa_file_str: {
      type: String
    },
    cn_identify_num: {
      type: String
    },
    cn_identify_num_str: {
      type: String
    },
    cn_identify_country: {
      type: String
    },
    cn_identify_country_str: {
      type: String
    },
    cn_identify_front_file: {
      tpye: Array
    },
    cn_identify_front_file_str: {
      type: String
    },
    cn_identify_back_file: {
      tpye: Array
    },
    cn_identify_back_file_str: {
      type: String
    },
    cn_bank_num: {
      type: String
    },
    cn_bank_num_str: {
      type: String
    },
    cn_bank_country: {
      type: String
    },
    cn_bank_country_str: {
      type: String
    },
    cn_bank_file: {
      tpye: Array
    },
    cn_bank_file_str: {
      type: String
    },
    cn_passport_option_num: {
      type: String
    },
    cn_passport_option_num_str: {
      type: String
    },
    cn_passport_option_country: {
      type: String
    },
    cn_passport_option_country_str: {
      type: String
    },
    cn_passport_option_file: {
      tpye: Array
    },
    cn_passport_option_file_str: {
      type: String
    },
    cn_passport_visa_option_file: {
      tpye: Array
    },
    cn_passport_visa_option_file_str: {
      type: String
    },
    other_passport_option_num: {
      type: String
    },
    other_passport_option_num_str: {
      type: String
    },
    other_passport_option_country: {
      type: String
    },
    other_passport_option_country_str: {
      type: String
    },
    other_passport_option_file: {
      tpye: Array
    },
    other_passport_option_file_str: {
      type: String
    },
    other_passport_visa_option_file: {
      tpye: Array
    },
    other_passport_visa_option_file_str: {
      type: String
    }
  },
  methods: {
    handleSelect (e, variable) {
      this.$emit('handleChange', variable, e)
    },
    FileValidate (e) {
      this.$emit('FileValidate', e)
    },
    handleInput (e, variable) {
      const { value } = e.target
      this.$emit('handleChange', variable, value)
    },
    handleFileList (e, variable) {
      this.$emit('handleChange', variable, e)
    },
    handleChange (variable, value) {
      this.$emit('handleChange', variable, value)
    }
  },
  computed: {
    ...mapState('countrySelection', ['popularCountryList', 'allCountryList']),
    ...mapGetters('layout', ['GetScreenStyle']),
    idStructure () {
      return {
        face_to_face: {
          nzauPassport: [
            this.nzPassportFile
          ],
          cnPassport_cnIdentify: [
            this.cnPassport,
            this.cnIdentify
          ],
          cnPassport_nzauDriver: [
            this.cnPassport,
            this.nzDriverFile
          ],
          otherPassport_nzauDriver: [
            this.otherPassport,
            this.nzDriverFile
          ]
        },
        none_face_to_face: {
          nzauPassport_nzauDriver: [
            this.nzPassportFile,
            this.nzDriverFile
          ],
          nzauDriver_cnIdentify: [
            this.nzDriverFile,
            this.cnIdentify,
            this.cnPassportOption
          ],
          cnIdentify_cnBank_cnPassport: [
            this.cnIdentify,
            this.cnBankCard,
            this.cnPassport
          ]
        }
      }
    },
    nzPassportFile () {
      return {
        id: 15,
        desc: 'New Zealand/Australia Passport 新西兰/澳大利亚护照 (the copy of passport photo and signature page 护照信息页和签字页) ',
        message: [{
          mid: 52,
          proof_num: this.nz_au_passport_num,
          proof_num_str: this.nz_au_passport_num_str,
          belongs_to: this.nz_au_passport_country,
          belongs_to_str: this.nz_au_passport_country_str,
          country_selection: [
            {
              name: 'New Zealand',
              dial_code: '+64',
              code: 'NZ'
            },
            {
              name: 'Australia',
              dial_code: '+61',
              code: 'AU'
            }
          ]
        }],
        upload: [
          {
            fid: 53,
            title: 'New Zealand/Australia护照信息页+签字页',
            prop: this.nz_au_passport_file_str,
            file: this.nz_au_passport_file
          }
        ]
      }
    },
    nzDriverFile () {
      return {
        id: 2,
        desc: 'New Zealand/Australia driver licence (the copy of front and back side 新西兰/澳大利亚驾照正反面)',
        message: [{
          mid: 1,
          proof_num: this.nz_au_driver_num,
          proof_num_str: this.nz_au_driver_num_str,
          belongs_to: this.nz_au_driver_country,
          belongs_to_str: this.nz_au_driver_country_str,
          belongs_to_str_disabled: false,
          country_selection: [
            {
              name: 'New Zealand',
              dial_code: '+64',
              code: 'NZ'
            },
            {
              name: 'Australia',
              dial_code: '+61',
              code: 'AU'
            }
          ]
        }],
        upload: [
          {
            fid: 2,
            title: '新西兰/澳大利亚驾照正面',
            prop: this.nz_au_driver_front_file_str,
            file: this.nz_au_driver_front_file
          },
          {
            fid: 3,
            title: '新西兰/澳大利亚驾照反面',
            prop: this.nz_au_driver_back_file_str,
            file: this.nz_au_driver_back_file
          }
        ]
      }
    },
    cnPassport () {
      return {
        id: 3,
        desc: 'Chinese passport 中国护照 (the copy of passport photo护照信息页) ',
        message: [
          {
            mid: 1,
            proof_num: this.cn_passport_num,
            proof_num_str: this.cn_passport_num_str,
            belongs_to: this.cn_passport_country,
            belongs_to_str: this.cn_passport_country_str,
            belongs_to_str_disabled: true,
            country_selection: [
              {
                name: 'China',
                dial_code: '+86',
                code: 'CN'
              }
            ]
          }
        ],
        upload: [
          {
            fid: 4,
            title: '中国护照信息页+签字页',
            prop: this.cn_passport_file_str,
            file: this.cn_passport_file
          },
          {
            fid: 5,
            title: '中国护照签证页',
            prop: this.cn_passport_visa_file_str,
            file: this.cn_passport_visa_file
          }
        ]
      }
    },
    cnIdentify () {
      return {
        desc: 'Chinese National ID (the copy of front and back side 身份证正反面) ',
        id: 5,
        message: [
          {
            mid: 3,
            proof_num: this.cn_identify_num,
            proof_num_str: this.cn_identify_num_str,
            belongs_to: this.cn_identify_country,
            belongs_to_str: this.cn_identify_country_str,
            belongs_to_str_disabled: true,
            country_selection: [
              {
                name: 'China',
                dial_code: '+86',
                code: 'CN'
              }
            ]
          }
        ],
        upload: [
          {
            fid: 6,
            title: '中国身份证正面',
            prop: this.cn_identify_front_file_str,
            file: this.cn_identify_front_file
          },
          {
            fid: 7,
            title: '中国身份证反面',
            prop: this.cn_identify_back_file_str,
            file: this.cn_identify_back_file
          }
        ]
      }
    },
    cnBankCard () {
      return {
        id: 6,
        desc: 'Chinese bank card (the copy of front and back side 银行卡正面)',
        message: [
          {
            mid: 4,
            proof_num: this.cn_bank_num,
            proof_num_str: this.cn_bank_num_str,
            belongs_to: this.cn_bank_country,
            belongs_to_str: this.cn_bank_country_str,
            belongs_to_str_disabled: true,
            country_selection: [
              {
                name: 'China',
                dial_code: '+86',
                code: 'CN'
              }
            ]
          }
        ],
        upload: [
          {
            fid: 8,
            prop: this.cn_bank_file_str,
            title: '中国银行卡正面',
            file: this.cn_bank_file
          }
        ]
      }
    },
    cnPassportOption () {
      return {
        id: 7,
        desc: 'Chinese passport 中国护照 (the copy of passport photo护照信息页)(如有)',
        message: [
          {
            mid: 5,
            proof_num: this.cn_passport_option_num,
            proof_num_str: 'cn_passport_option_num_str',
            belongs_to: this.cn_passport_option_country,
            belongs_to_str: this.cn_passport_option_country_str,
            belongs_to_str_disabled: true,
            country_selection: [
              {
                name: 'China',
                dial_code: '+86',
                code: 'CN'
              }
            ]
          }
        ],
        upload: [
          {
            fid: 10,
            title: '中国护照信息页+签字页(如有)',
            prop: this.cn_passport_option_file_str,
            file: this.cn_passport_option_file
          },
          {
            fid: 11,
            title: '中国护照签证页(如有)',
            prop: this.cn_passport_visa_option_file_str,
            file: this.cn_passport_visa_option_file
          }
        ]
      }
    },
    otherPassport () {
      return {
        id: 9,
        desc: 'Other passport 其他护照 (the copy of passport photo 护照信息页)',
        message: [
          {
            mid: 7,
            proof_num: this.other_passport_option_num,
            proof_num_str: this.other_passport_option_num_str,
            belongs_to: this.other_passport_option_country,
            belongs_to_str: this.other_passport_option_country_str,
            belongs_to_str_disabled: false,
            country_selection: this.allCountryList
          }
        ],
        upload: [
          {
            fid: 12,
            title: '其他护照信息页+签字页',
            prop: this.other_passport_option_file_str,
            file: this.other_passport_option_file
          },
          {
            fid: 13,
            title: '其他护照签证页',
            prop: this.other_passport_visa_option_file_str,
            file: this.other_passport_visa_option_file
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.step_sub_title {
  margin: 20px 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 500;
}
.radio_step_ul_style {
  padding-left: 5px;
  font-size: 16px;
  font-weight: 300;
}
.radio_step_ul_style li {
  line-height: 24px;
}
.step_ul_style {
  padding-left: 20px;
  font-size: 16px;
  font-weight: 300;
}
.step_ul_style li {
  line-height: 24px;
}
.step_ul_style .li_require::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1rem;
}
.modify_antd_radio >>> .ant-radio-inner {
  width: 26px;
  height: 26px;
}
.modify_antd_radio >>> .ant-radio-wrapper {
  white-space: pre-wrap;
  display: flex;
}
.modify_antd_radio >>> .ant-radio-inner::after {
  width: 12px;
  left: 6px;
  top: 6px;
  height: 12px;
}
.modify_antd_radio >>> .ant-radio-checked::after {
  height: 25px;
}
</style>
